import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image'

import { convertToBgImage } from '../../utils/utils'

import PageHeroSideElements from '../PageHeroSideElements'

import './SingleHero.scss'

/**
 * SingleHero
 * 
 * @param {Object} param.heroImage
 * @param {Object} param.categories
 * @param {String} param.title
 * @param {String} param.copyrightField
 * 
 * @returns {JSX}
 */
export default function SingleHero({heroImage, categories, title, copyrightField}) {

  const hero = getImage(heroImage.localFile.childImageSharp.gatsbyImageData)

  const bgImage = convertToBgImage(hero)
  
  return (
    <div className="single-hero">
      <BackgroundImage
        Tag="div"
        {...bgImage}
        preserveStackingContext
        className="single-hero__image"
        alt={heroImage.altText ? heroImage.altText : 'Hero'}
      >
        <div className="single-hero__overlay"></div>
        <div className="content-wrapper single-hero__content-wrapper">
          <div className="single-hero__content">
            <h1 className="single-hero__content-title">{title}</h1>
            <p className="single-hero__content-subtitle">
              {
                categories.nodes.map((item, index) => {
                  return `${item.name} ${index !== categories.nodes.length - 1 ? '/' : ''} `
                })
              }
            </p>
          </div>
        </div>
        <PageHeroSideElements copyright={copyrightField} isLightTheme={true}/>
      </BackgroundImage>
    </div>
  )
}

SingleHero.propTypes = {
  heroImage: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired
}