import React from 'react'
import PropTypes from 'prop-types'

import {GatsbyImage} from 'gatsby-plugin-image'

import CustomNavLink from '../CustomNavLink'
import Arrow from '../../svg-images/arrow.svg'

import './NextProjectComponent.scss'

/**
 * NextProjectComponent
 * 
 * @param {Object} param.nextProject
 * 
 * @returns {JSX}
 */
export default function NextProjectComponent({nextProject}) {

  return (
    <div className="next-project">
      <div className="content-wrapper">
        <CustomNavLink classes="next-project__link" path={nextProject.uri}>
          <div className="next-project__content">
            <div className="next-project__image-wrapper">
              <div className="next-project__image-container">
                <GatsbyImage 
                  className="next-project__image"
                  image={nextProject.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                  alt={nextProject.featuredImage.node.alt ? nextProject.featuredImage.node.alt : 'Project image'}
                />
              </div>
            </div>
            <div className="next-project__text">
              <div className="next-project__text-wrapper">
                <div className="next-project__text-next">
                  <p className="next-project__text-next-label">Next project</p>
                  <Arrow className="next-project__text-next-arrow"/>
                </div>
                <p className="next-project__text-project">{nextProject.title}</p>
              </div>
            </div>
          </div>
        </CustomNavLink>
      </div>
    </div>
  )
}

NextProjectComponent.propTypes = {
  nextProject: PropTypes.object.isRequired
}