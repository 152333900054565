import React from 'react'
import PropTypes from 'prop-types'

import {GatsbyImage} from 'gatsby-plugin-image'

import './ThoughtsComponent.scss'

/**
 * ThoughtsComponent
 * 
 * @param {String} param.name
 * @param {String} param.position
 * @param {String} param.image
 * @param {Object} param.content
 * 
 * @returns {JSX}
 */
export default function ThoughtsComponent({name, position, image, content}) {

  return (
    <div className="thoughts">
      <div className="content-wrapper">
        <div className="thoughts__wrapper">
          <p className="thoughts__label">/THOUGHTS</p>
          <div className="thoughts__person">
            <div className="thoughts__person-image-wrapper">
              {
                image &&
                <GatsbyImage 
                  image={image.localFile.childImageSharp.gatsbyImageData}
                  alt={image.altText ? image.altText : 'Client'}
                  className="thoughts__person-image"
                />
              }
            </div>
            <p className="thoughts__person-name">{name}</p>
            <p className="thoughts__person-position">{position}</p>
          </div>
          <p className="thoughts__content">{`"${content}"`}</p>
        </div>
      </div>
    </div>
  )
}

ThoughtsComponent.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired
}