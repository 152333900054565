import React from 'react'
import PropTypes from 'prop-types'
import ProjectInfoComponentList from './ProjectInfoComponentList'

import './ProjectInfoComponent.scss'

/**
 * ProjectInfoComponent
 * 
 * @param {String} param.projectInfoContent
 * @param {Array} param.projectInfoRepeater
 * 
 * @returns {JSX}
 */
export default function ProjectInfoComponent({projectInfoContent, projectInfoRepeater}) {
  
  return (
    <div className="project-info__container">
      <div className="project-info">
        <div className="content-wrapper"> 
          <div className="project-info__wrapper">
            <div className="project-info__repeater">
              {
                projectInfoRepeater.map((listItem, index) => {
                  return (
                    <ProjectInfoComponentList listItem={listItem} key={index}/>
                  )
                })
              }
            </div>
            <div className="project-info__text-wrapper">
              <p className="project-info__text">{projectInfoContent}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProjectInfoComponent.propTypes = {
  projectInfoContent: PropTypes.string.isRequired,
  projectInfoRepeater: PropTypes.array.isRequired
}