import React from 'react'
import PropTypes from 'prop-types'

import SingleShowcaseImage from './SingleShowcaseImage'

import './ShowcaseImages.scss'

/**
 * ShowcaseImages
 * 
 * @param {Array} param.imageRepeater
 * 
 * @returns {JSX}
 */
export default function ShowcaseImages({imageRepeater}) {
  
  return (
    <div className="showcase-image">
      <div className="content-wrapper">
        {
          imageRepeater.map((image, index) => {
            return (                
              <SingleShowcaseImage image={image} key={index}/>
            )
          })
        }
      </div>
    </div>
  )
}

ShowcaseImages.propTypes = {
  imageRepeater: PropTypes.array.isRequired
}