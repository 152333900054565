import React from 'react'
import PropTypes from 'prop-types'

/**
 * ProjectInfoComponentList
 * 
 * @param {Object} param.listItem
 * 
 * @returns {JSX}
 */
export default function ProjectInfoComponentList({listItem}) {
  
  return (
    <div className="project-info__repeater-list">
      <p className="project-info__repeater-list-title">{listItem.infoTitle}</p>
      <div className="project-info__repeater-list-items">
        {
          listItem.infos.map((item, index) => {
            return(
              <p className="project-info__repeater-list-item" key={index}>{item.singleInfo}</p>
            )
          })
        }
      </div>
    </div>
  )
}

ProjectInfoComponentList.propTypes = {
  listItem: PropTypes.object.isRequired
}