import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SingleHero from '../components/single-work/SingleHero'
import ProjectInfoComponent from '../components/single-work/ProjectInfoComponent'
import ShowcaseImages from '../components/single-work/ShowcaseImages'
import SimpleTextComponent from '../components/SimpleTextComponent'
import FullWidthImage from '../components/FullWidthImage'
import TwoColumnText from '../components/TwoColumnText'
import ThoughtsComponent from '../components/single-work/ThoughtsComponent'
import NextProjectComponent from '../components/single-work/NextProjectComponent'
import Layout from '../components/Layout'

import { useNavigateLink } from '../hooks/useNavigateLink'

export default function SingleWorkTemplate({ data, pageContext, location }) {

  const { state } = location

  const { wpWork: { singleFlexibleContent: { singleFlexibleContent }, categories, title }, allWpMenu: {menus}, allWp: { nodes } } = data
  const { acfOptionsOptionsPage: { optionsPage: { copyrightField } } } = nodes[0]
  
  const mainMenu = menus.filter(menu => menu.locations[0] === 'MENU_1');
  const footerMenu = menus.filter(menu => menu.locations[0] === 'FOOTER_MENU');

  // Controls curtain transition
  useNavigateLink(location.pathname)

  return (
    <Layout mainMenu={mainMenu} footerMenu={footerMenu} copyrightField={copyrightField} shouldAnimate={state?.shouldAnimate} title={title}>
      <>
        {
          singleFlexibleContent.map((component, index) => {
            switch (component.fieldGroupName) {
              case "Page_Pageflexiblecontent_FlexibleContent_SimpleTextComponent":
                return <SimpleTextComponent title={component.sectionTitle} content={component.sectionContent} key={index}/>
              case "Work_Singleflexiblecontent_SingleFlexibleContent_TwoColumnsTitleContent":
                return <TwoColumnText label={component.sectionTitle} title={component.title} content={component.content} key={index}/>
              case "Work_Singleflexiblecontent_SingleFlexibleContent_FullWidthImage":
                return <FullWidthImage image={component.image} key={index}/>
              case "Work_Singleflexiblecontent_SingleFlexibleContent_WorkHero":
                return <SingleHero heroImage={component.heroImage} categories={categories} title={title} copyrightField={copyrightField} key={index}/>
                case "Work_Singleflexiblecontent_SingleFlexibleContent_ProjectInfo":
                  return <ProjectInfoComponent projectInfoContent={component.projectInfoContent} projectInfoRepeater={component.infoRepeater} key={index}/>
                case "Work_Singleflexiblecontent_SingleFlexibleContent_SingleImage":
                  return <ShowcaseImages imageRepeater={component.imageRepeater} key={index}/>
                case "Work_Singleflexiblecontent_SingleFlexibleContent_Thoughts":
                  return <ThoughtsComponent name={component.personName} position={component.personPosition} image={component.image} content={component.content} key={index}/>
              default:
                return null
            }
          })
        }
        <NextProjectComponent nextProject={pageContext.next}/>
      </>
    </Layout>
  )
}

SingleWorkTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export const flexibleContentSingleWorkQuery = graphql`
  query cptWorkByID($id: String!) {
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        acfOptionsOptionsPage {
          optionsPage {
            copyrightField
          }
        }
        generalSettings {
          url
        }
      }
    }
    wpWork(id: {eq: $id}) {
      title
      content
      categories {
        nodes {
          name
          slug
        }
      }
      singleFlexibleContent {
        singleFlexibleContent {
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_WorkHero {
            fieldGroupName
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_ProjectInfo {
            fieldGroupName
            projectInfoContent
            infoRepeater {
              fieldGroupName
              infoTitle
              infos {
                singleInfo
              }
            }
          }
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_SingleImage {
            fieldGroupName
            imageRepeater {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_TwoColumnsTitleContent {
            content
            fieldGroupName
            sectionTitle
            title
          }
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_Thoughts {
            content
            fieldGroupName
            personName
            personPosition
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 110
                  )
                }
              }
            }
          }
          ... on WpWork_Singleflexiblecontent_SingleFlexibleContent_FullWidthImage {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`