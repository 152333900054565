import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import useIntersect from '../../hooks/useIntersect'

/**
 * SingleShowcaseImage
 * 
 * @param {Object} param.image
 * 
 * @returns {JSX}
 */
export default function SingleShowcaseImage({image}) {

  const [isInView, setIsInView] = useState(false)

  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: '0% 0% -10%'
  })

  useEffect(() => {
    if(entry.isIntersecting) {
      !isInView && setIsInView(true);
    }
  }, [entry.isIntersecting, isInView])
  
  return (
    <div className={`showcase-image__wrapper ${isInView ? 'showcase-image__wrapper--in-view' : ''}`} ref={ref}>
      <GatsbyImage 
        className="showcase-image__image" 
        alt={image.image.altText ? image.image.altText : ''} 
        image={image.image.localFile.childImageSharp.gatsbyImageData}
      />
    </div>
  )
}

SingleShowcaseImage.propTypes = {
  image: PropTypes.object.isRequired
}